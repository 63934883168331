import './App.css';
import'./pages/Pages.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Products from './pages/Products';
import PaymentInformation from './pages/payment_page';
import ContactUs from './pages/ContactUs';
import SafetyTips from './pages/SafetyTips';
import OrderPreview from './pages/OrderPreview';
// import NoPage from './pages/NoPage';
import Billpreview from './pdf/Billpreview';
import GTM from './GTM';
import SiteOff from './pages/SiteOff';

function App() {
  return (
    <div className="App">
      <GTM />
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Products/>}/> 
            <Route path='/paymentinformation' element={<PaymentInformation/>}/> 
            <Route path='/safetytips' element={<SafetyTips/>}/> 
            <Route path='/contactus' element={<ContactUs/>}/>
            <Route path='/orderpreview' element={<OrderPreview/>}/>
            <Route path='/nopage' element={<Billpreview/>}/>
            <Route path='/siteoff' element={<SiteOff/>}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
