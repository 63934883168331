import React from 'react';

const GTM = () => (
    <noscript>
        <iframe
            src={`https://www.googletagmanager.com/ns.html?id=GTM-PSRFXN5L`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
    </noscript>
);

export default GTM;
