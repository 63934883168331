import React from 'react';
import MetaTags from './MetaTags';
import AppBar from './AppBar'
import { Container,Row,Col } from 'react-bootstrap'
import { SiPaytm } from "react-icons/si";
import { CiBank } from "react-icons/ci";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import Mark from './Mark';
const PaymentInformation = () => {
  return (
    <div>
       <MetaTags
        title="Payment Information - RM Crackers - Whole sale Crackers"
        type="website"
        siteName="rmcrackers.com"
        url="https://rmcrackers.com/contactus"
        keywords="Contact Us | RM Crackers "
        description="Contact for wholesale Crackers and Retail Crackers"
        revisitAfter="10 Days"
      />
      <AppBar/>
      <img src={require('../assets/images/b3.jpg')} className='img-fluid w-100' alt='product name' />
        <Container className='padding'>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className='py-3'>
                <div className='bold  text-center'> Payment Information</div>
              </Col>
              <Col lg="5">
                <div className='box'>
                    <div className='box-icon'>
                    <CiBank size={40} color={'#fff'}/> 
                    </div>
                    <div className='regular'>
                    <div>Bank : AXIS BANK, SIVAKASI</div>
                    <div>A/C No : 922020045289362</div>
                    <div>Name : R.M. CRACKERS</div>
                    <div>IFSC : UTIB0000089</div>
                    </div>
                </div>
              </Col>
              <Col lg="5">
                <div className='box'>
                    <div className='box-icon'>
                    <SiPaytm  size={40} color={'#fff'}/>
                    </div>
                    <div>
                    <img src={require('../assets/images/upi.jpeg')} className='img-fluid w-50' alt='product name' />
                    </div>
                </div>
              </Col>
              
            </Row>
        </Container>
       
        <Mark/>
        
    </div>
  )
}

export default PaymentInformation