import React from 'react';
import AppBar from './AppBar';
import Footer from './Footer'
import MetaTags from './MetaTags';
import { Container,Row,Col } from 'react-bootstrap';
import { FaRegFaceSmile } from "react-icons/fa6";
import { CgSmileSad } from "react-icons/cg";
const SafetyTips = () => {
  return (
    <div>
      <MetaTags
        title="Safety Tips - RM Crackers - Whole sale Crackers"
        type="website"
        siteName="rmcrackers.com"
        url="https://rmcrackers.com/safetytips"
        keywords="Safety Tips | RM Crackers | Fireworks Safety"
        description="Learn essential safety tips for handling fireworks Crackers Name."
        revisitAfter="10 Days"
      />
      <AppBar />
      <img src={require('../assets/images/safety.jpg')} className='img-fluid w-100' alt='product name' />
      <>
        <Container className='padding'>
            <Row>
              <Col lg='12' className='py-3'>
                <h1 className='text-center bold'>Safety Tips</h1>
              </Col>
              <Col lg='6' className='py-3'>
                <div className='text-center py-3'>
                  <div className='title shadow bg-green'> Do </div>
                </div>
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Follow Instructions</div>
                    <div>Display fireworks as per the instructions mentioned on the pack.</div>
                  </div>
                </div>
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Outdoor Use Only</div>
                    <div>Use fireworks only outdoor</div>
                  </div>
                </div>
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Use Branded Fireworks</div>
                    <div>Buy fireworks from authorized/reputed manufacturers only.</div>
                  </div>
                </div>
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Follow Safety Tips</div>
                    <div>Always follow the safety tips marked on the fireworks.</div>
                  </div>
                </div>
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Safe Storage</div>
                    <div>Store fireworks in a cool and dry place.</div>
                  </div>
                </div>

              </Col>
              <Col lg='6' className='py-3'>
                <div className='text-center py-3'>
                  <div className='title shadow bg-red'> Dont 's </div>
                </div>
                <div className='safe-list box-red'>
                  <div className='safe-icon'>
                    <CgSmileSad />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Follow Instructions</div>
                    <div>Display fireworks as per the instructions mentioned on the pack.</div>
                  </div>
                </div>
                <div className='safe-list box-red'>
                  <div className='safe-icon'>
                    <CgSmileSad />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Don’t place near candles</div>
                    <div>Don’t store firecrackers near burning candles or lamps.</div>
                  </div>
                </div>
                <div className='safe-list box-red'>
                  <div className='safe-icon'>
                    <CgSmileSad />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Don’t wear loose clothes</div>
                    <div>Do not wear loose clothing while using fireworks.</div>
                  </div>
                </div>
                <div className='safe-list box-red'>
                  <div className='safe-icon'>
                    <CgSmileSad />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Do not Throw</div>
                    <div>Never point or throw fireworks at another person.</div>
                  </div>
                </div>
                <div className='safe-list box-red'>
                  <div className='safe-icon'>
                    <CgSmileSad />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Do not use Glass / Metal</div>
                    <div>Never shoot fireworks in metal or glass containers.</div>
                  </div>
                </div>
              </Col>
            </Row>
        </Container>
      </>
      <Footer/>
    </div>
  );
};

export default SafetyTips;
