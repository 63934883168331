import React from 'react'
import { Container,Nav,Navbar,Row,Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Marquee from "react-fast-marquee";

const AppBar = () => {
  return (
    <>
    <>
    <div className='mark-bg'>
      <Container fluid>
          <Row>
            <Col lg='12' className='py-2'>
              <Marquee pauseOnHover={true}  direction="left" speed={50} delay={2}   >
                 Whatsapp : +91 95850 30419 | Alter No : +91 96006 53111 |  Email : rmcrackers2526@gmail.com
              </Marquee>
            </Col>
          </Row>
      </Container>
    </div>
    </>
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <div className='logo'>
              <img src={require('../assets/images/storelogo.png')} className='img-fluid' alt='product name' />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto text-center">
              <Nav.Link as={NavLink} exact to={"/"} activeClassName="active">Products</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/paymentinformation"} activeClassName="active">Payment Information</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/safetytips"} activeClassName="active">Safety Tips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contactus"} activeClassName="active">Contact Us</Nav.Link>
            </Nav>
            <Nav.Link as={NavLink} exact to={"/"} activeClassName="active" className='text-center estimate'>Estimate Now !!!</Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>

    </>
  )
}

export default AppBar